<template>
  <div class="EquipmentOperationMonitoring">
    <headers :BackUrl="BackUrl"></headers>
    <div class="HeaderTab">
      <div class="tabs" v-for="(item,index) in List" :key="index" @click="tap(index)" 
      :class="(item.path.indexOf($route.path)>-1)||($route.path == '/StatusDetails' && $route.query.activeId == item.id) ? 'active' : ''">{{item.name}}</div>
      <div class="Header_Bck_box" v-if="$route.path == '/EquipmentLayoutView' || $route.path == '/StatusDetails'" @click="handleBack">
        <div class="HeaderIcon"></div>
        <div class="HeaderBck">返回上一级</div>
      </div>
    </div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import headers from '@/components/header/headermain.vue'
export default {
  components: {
		headers
  },
  data() {
    return {
      List:[{
        name:'设备总览',
        id:1,
        path:['/EquipmentOperationManage','/EquipmentLayoutView'],
      },{
        name:'设备运行状态监测',
        id:2,
        path:['/EquipmentOperaStatusView'],
      },{
        name:'设备报警管理',
        id:3,
        path:['/EquipmentAlarmManagement'],
      }],
      activeIndex: 0,
      BackUrl:'',
    }
  },
  watch:{
    // '$route.path'(newVal,oldVal){
    //   if (newVal == '/EquipmentLayoutView') {
    //     this.BackUrl = '/EquipmentOperationManage'

    //   }else if(newVal == '/StatusDetails'){
    //     this.BackUrl = '/EquipmentLayoutView'
    //   }
    // },
  },
  methods: {
    tap(index){
      this.activeIndex=index;
      if(index==0){ //设备总览
        this.$router.push({path:'/EquipmentOperationManage', query:{ title:'设备总览'}})
      }else if(index==1){ //设备运行状态监测
        this.$router.push({path:'/EquipmentOperaStatusView', query:{ title:'设备运行状态监测'}})
      }else if(index==2){ //设备报警管理
        this.$router.push({path:'/EquipmentAlarmManagement', query:{ title:'设备报警管理'} })
      }
    },
    handleBack(){
        //设备总览  EquipmentLayoutView
        // console.log(this.$route);
        // StatusDetails
        if (this.$route.path == '/EquipmentLayoutView') {
          this.$router.push({path:'/EquipmentOperationManage'})
        }else if(this.$route.path == '/StatusDetails'){
          this.$router.go(-1)
        }
    },
  },
}
</script>

<style scoped lang="scss">
.HeaderTab .active{
	color: #3bffc3 !important;
	// border-bottom: 3px solid #3bffc3;
	background: url('../../../static/images/tab_active.png');
	// background-size: 100% 100%;
	background-repeat: no-repeat;
}
.EquipmentOperationMonitoring{
  height: 100%;
}
.Header_Bck_box{
  position: absolute;
  right: 0;
  bottom: -10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.HeaderIcon{
  width: 16px;
  height: 16px;
  margin-right: 5px;
  background: url('../../../static/images/btn_back.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
}
.HeaderBck{
  color: #00d392;
  font-size: 14px;
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
  .HeaderTab{
    width: 98%;
    height: 40px;
    margin-left: 15px;
    line-height: 40px;
    font-size:18px;
    // @include border_color("border_color_white");
    margin-bottom: 20px;
    position: relative;
  }
  .tabs{
    min-width:100px;
    padding-left: 0px;
    padding-right: 0px;
    height: 40px;
    line-height: 40px;
    width: 240px;
    margin-right: 30px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    background: url('../../../static/images/tab_normal.png');
    // background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
@media only screen and (min-aspect-ratio: 1920/1000 ) {
  .HeaderTab{
    width: 98%;
    height: 40px;
    margin-left: 15px;
    line-height: 40px;
    font-size:18px;
    // @include border_color("border_color_white");
    margin-bottom: 20px;
    position: relative;
  }
  .tabs{
    min-width:100px;
    padding-left: 0px;
    padding-right: 0px;
    height: 4vh;
    line-height: 4vh;
    width: 240px;
    background: url('../../../static/images/tab_normal.png');
    // background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: 30px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }
}
</style>